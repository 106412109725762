@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
body {
    margin: 0;
    font-family: "Barlow";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Barlow";
}

@layer components {
    .dashboard-right-menu-dropdown {
        @apply block py-2 text-gray-700 hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md;
    }
    .login-page-buttons {
        @apply flex justify-center  items-center border-[1px] border-[#000000] bg-[#FFFFFF] text-[#1C2347] font-[700] mb-3 max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-r from-blue-500 to-purple-600 hover:text-white w-full py-3 rounded-lg;
    }
    .message-container {
        @apply text-center fixed z-[9999999999] top-5 left-0 w-full max-md:top-2.5;
    }
    .message-container > div {
        @apply m-0 text-left bg-white min-w-message max-w-message inline-block py-5 px-4 rounded-md overflow-hidden shadow-message max-md:py-2.5 max-md:px-1.5 max-md:shadow-message2;
    }
    .message-container > div > div {
        @apply grid grid-cols-[55px_1fr_80px] items-center max-md:grid-cols-[45px_1fr_60px];
    }
    .message-container > div > div > div {
        @apply border-r-[1px] border-[#777777] pr-5 max-md:pr-2.5;
    }
    .message-container img {
        @apply w-10;
    }
    .message-container h4,
    .message-container h5,
    .message-container p {
        @apply m-0;
    }
    .message-container h4 {
        @apply font-medium text-xl mb-1;
    }
    .message-container h5 {
        @apply text-[#777777] text-base;
    }
    .message-container p {
        @apply text-[#ABABAB] text-base cursor-pointer text-center pl-4 max-md:pl-0;
    }
    .message-container p:hover {
        @apply text-[#777777];
    }
    .error-message-box {
        @apply border-l-8 border-[#FE2343] text-[#FE2343];
    }
    .success-message-box {
        @apply border-l-8 border-[#24B056] text-[#24B056];
    }
    .input-number {
        /* Ensure the input is wide enough to accommodate the arrows */
        width: 2.5rem;
        /* Remove the default arrows on Firefox */
        -moz-appearance: textfield;
    }
    .ai-voice-cloning-navbar {
        @apply flex justify-center items-center rounded-r-[32px] rounded-l-[32px] py-3 text-[16px] leading-[19px] font-[500] text-[#969696]  mx-2 px-4 hover:bg-[#489BF636] hover:text-[#489BF6] cursor-pointer;
    }
    .ai-voice-cloning-navbar-active {
        @apply flex justify-center items-center rounded-r-[32px] rounded-l-[32px] py-3 text-[16px] leading-[19px] font-[500] mx-2 px-4 bg-[#489BF636] text-[#489BF6] cursor-pointer;
    }
    .ai-voice-cloning-navbar > img {
        @apply pr-2;
    }
    .ai-voice-cloning-navbar-active > img {
        @apply pr-2;
    }
    .speech-header-active {
        @apply text-[#489BF6] font-[500] text-[16px] leading-[19px] cursor-pointer text-center w-full bg-[#F4F6F8] border-[#489BF6] border-x-[1px] border-t-[1px];
    }
    .speech-header-active > div:first-child {
        @apply h-[7px] w-full bg-[#489BF6];
    }
    .speech-header-active > div:last-child {
        @apply py-4 flex justify-center;
    }
    .speech-header-inactive {
        @apply text-[#A7A7A7] font-[500] text-[16px] leading-[19px] cursor-pointer  text-center w-full bg-[#FFFFFF1F] border-[#C1C3C0] border-x-[1px] border-t-[1px] border-b-[1px] border-b-[#489BF6];
    }
    .speech-header-inactive > div:first-child {
        @apply h-[7px] w-full bg-[#FFFFFF1F];
    }
    .speech-header-inactive > div:last-child {
        @apply py-4 flex justify-center;
    }
    .custom-scrollbar::-webkit-scrollbar {
        width: 0px;
        /* Set to 0 to hide scrollbar in Chrome, Safari, and Edge */
    }
    .custom-scrollbar {
        scrollbar-width: none; /* For Firefox */
    }
    .thin-scrollbar::-webkit-scrollbar {
        width: 6px; /* Set desired width for scrollbar */
    }

    .thin-scrollbar::-webkit-scrollbar-track {
        background-color: #d9d9d9; /* Background color of the scrollbar track */
    }

    .thin-scrollbar::-webkit-scrollbar-thumb {
        background-color: #565656; /* Color of the scrollbar thumb */
        border-radius: 3px; /* Rounded corners for the thumb */
    }

    /* Firefox scrollbar */
    .thin-scrollbar {
        scrollbar-width: thin; /* Set desired width for scrollbar in Firefox */
    }
    /* Remove the spinner arrows on Chrome */
    .input-number::-webkit-inner-spin-button,
    .input-number::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /********* Generate Key CSS *********/
    .generate-key-header {
        @apply grid grid-cols-[100px_1fr_2fr_2fr_100px] my-5 py-4 bg-[#F8F8F8]  max-md:hidden;
    }
    .generate-key-header > h5 {
        @apply text-[#969696] font-[500] text-[16px] leading-[18px];
    }
    .generate-key-table {
        @apply py-4 my-2 text-[14px] text-[#646464] border-[1px] border-[#D6E8FD] rounded-md grid grid-cols-[100px_1fr_2fr_2fr_100px] max-md:grid-cols-[100px_1fr] max-md:text-left max-md:text-[12px];
    }
    .generate-key-table > span {
        @apply hidden max-md:block text-[#1C2347] font-[600] pl-1;
    }
    /*******************************/
    /*******Host Key CSS Begins*********/
    .host-key-header {
        @apply grid grid-cols-[100px_1fr_1fr_2fr_100px] my-5 py-4 bg-[#F8F8F8];
    }
    .host-key-header > h5 {
        @apply text-[#969696] font-[500] text-[16px] leading-[18px];
    }
    .host-key-table {
        @apply py-4 my-2 text-[14px] text-[#646464] border-[1px] border-[#D6E8FD] rounded-md grid grid-cols-[100px_1fr_1fr_2fr_100px];
    }
    /*******************************/
    .added-models-header {
        @apply grid grid-cols-[1fr_1fr_1fr_2fr_1fr_50px_1fr] px-2 text-center mt-5 py-4 bg-[#F0F0F0] pl-2 max-[1062px]:hidden;
    }
    .added-models-header > h5 {
        @apply text-[#969696] font-[500] text-[16px] leading-[18px] pl-1;
    }
    .added-models-table {
        @apply py-4 text-[12px] text-[#646464] border-[1px] border-[#F0F0F0] pl-1 rounded-md grid grid-cols-[1fr_1fr_1fr_2fr_1fr_50px_1fr] max-[1062px]:hidden;
    }
    .added-models-table > p {
        @apply border-r-[1px]  pl-2 border-[#F0F0F0] h-full text-[#5C5A5B];
    }
    .added-models-table > div:last-child {
        @apply flex items-center;
    }
    .added-models-table > div > div {
        @apply flex items-center justify-center px-2 bg-[#489BF626] rounded-full text-[#489BF6] py-1 mx-1 cursor-pointer hover:scale-[1.03] duration-300 hover:bg-[#F0F0F0];
    }
    .drop-zone {
        width: 100%;
        padding: 70px 80px;
        border: 2px dashed #ccc;
        border-radius: 15px;
        background-color: #f9f9f9;
        text-align: center;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .drop-zone.drag-over {
        border-color: #007bff;
        background-color: #e3f2fd;
    }

    .drop-zone p {
        margin-top: 10px; /* Add space between the icon and the text */
    }
    .mobile-view-list-ar {
        @apply border-2 text-[12px] p-2 my-2 rounded-lg hidden max-[1062px]:block;
    }
    .mobile-view-list-ar > p > span {
        @apply font-[600] text-[#1C2347];
    }
    .mobile-view-list-ar > p {
        @apply text-[#646464] py-0.5;
    }
    .custom-dropdown {
        @apply w-full relative text-left;
    }

    .dropdown-header {
        @apply px-4 py-2 my-2 border-[1px] border-[#ccc] cursor-pointer rounded-md bg-[#489BF6];
    }

    .dropdown-list {
        @apply absolute top-full left-0 right-0 border border-t-0 border-gray-300 bg-white z-[1000] rounded-md h-[200px] overflow-y-scroll thin-scrollbar shadow-2xl;
    }

    .dropdown-option {
        @apply cursor-pointer py-1 px-4 border-b-[1px];
    }

    .dropdown-option:hover {
        @apply bg-[#489BF6] text-white;
    }

    .option-heading {
        @apply font-[500];
    }

    .option-description {
        @apply font-[#595959] text-[12px];
    }
    .blur-background {
        filter: blur(1px);
        pointer-events: none;
    }

    .diagonal-text {
        transform: rotate(-45deg);
        /* background-color: rgba(0, 0, 0, 0.); */
        padding: 10px 20px;
        border-radius: 10px;
    }
    /*************Contact Us Acoordition********************/
    .wrapper-contactus {
        @apply flex justify-center items-center mb-2;
    }
    .accordition-contactus {
        @apply w-8/12 max-md:w-full;
    }
    .item-contactus {
        @apply mb-1 px-7 py-3 cursor-pointer;
    }
    .item-contactus h5 {
        @apply font-['lato'] text-[18px] font-semibold text-left max-md:text-[16px];
    }
    .item-contactus div:last-child {
        @apply font-['lato'] text-white text-[14px];
    }
    .tittle-contactus {
        @apply font-semibold flex justify-between items-center;
    }
    .content-contactus {
        @apply text-white font-['lato'] font-[300] text-left text-left ml-4 overflow-hidden transition-all max-h-0 text-[18px];
    }
    .content-contactus.show {
        @apply text-white font-['lato'] overflow-hidden transition-all h-auto max-h-96 py-1;
    }
    .height-of-inner-div {
        @apply h-[calc(100vh-193px)];
    }
    /******************************************/
    /*********Flyout and Flyin Animations ************/

    @keyframes flyout {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }
    @keyframes flyin {
        0% {
            transform: translateY(20px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .flyout {
        animation: flyout 0.5s forwards;
    }
    .flyin {
        animation: flyin 0.5s forwards;
    }
    .group-hover .flyout-hover {
        animation: flyout 0.5s forwards;
    }
    .group:hover .flyin-hover {
        animation: flyin 0.5s forwards;
    }
    .loaderCntMain {
        height: 100vh;
        background-color: rgb(255, 255, 255);
        padding: 0rem 0rem;
    }
    .loaderCnt {
        display: grid;
        grid-template-columns: 280px 1fr;
        height: 100%;
    }
    .leftDiv,
    .large,
    .small,
    .rightUpper,
    .rightLower {
        animation: sliderAnimation 0.7s linear infinite alternate;
    }
    .rightDiv {
        background-color: white;
        display: grid;
        grid-template-rows: 80px 1fr;
    }
    .rightHeader {
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .large {
        padding: 1.5rem 6rem;
        margin-right: 1rem;
    }
    .small {
        padding: 1.5rem;
        margin-right: 1rem;
    }
    .large,
    .small {
        border-radius: 0.25rem;
    }
    .rightMain {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1px;
    }
    .rightUpper,
    .rightLower {
        border-radius: 0.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .rightUpper {
        margin-bottom: 1rem;
    }

    @keyframes sliderAnimation {
        0% {
            background-color: rgb(224, 224, 224);
        }
        100% {
            background-color: rgb(248, 248, 248);
        }
    }

    @media only screen and (max-width: 600px) {
        .loaderCnt {
            display: block;
        }
        .leftDiv {
            height: 55px;
        }
        .rightHeader {
            display: flex;
            align-items: normal;
            gap: 10px;
            padding: 10px;
            height: 10px;
        }
        .large {
            margin: 0;
        }
        .small {
            padding: 1.5rem;
            margin-right: 1rem;
        }
        .rightMain {
            display: block;
        }
        .rightUpper,
        .rightLower {
            margin: 0 0.5rem 0.5rem 0.5rem;
        }
        .rightUpper {
            height: 60px;
        }
        .rightLower {
            height: calc(100vh - 275px);
        }
    }
}
